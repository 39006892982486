@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@100;300;400;700&family=DM+Sans:opsz,wght@9..40,400;9..40,500;9..40,700&family=Montserrat&family=Ubuntu:ital,wght@0,400;0,700;1,400&display=swap");
@import "../../../node_modules/bootstrap/dist/css/bootstrap.css";

:root {
  --text-color: #6c6c6c;
  --red: #e7302a;
  --white: #ffffff;
  --grey: #f6f6f6;
  --black: #1d1d1d;
  --red-hover: #c62924;
}

* {
  padding: 0;
  margin: 0;
}

body {
  color: var(--text-color) !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding-left: 0;
  margin-left: 0;
}

.p-absolute {
  position: absolute;
}

.b-0 {
  bottom: 0;
}
