/* Topbar */

#topbar {
  width: 100%;
  height: 90px;
  position: relative;
  z-index: 10000000 !important;
}

#topbar nav {
  padding: 10px 10%;
}

#topbar img.logo {
  height: 41px;
  width: auto;
}

#topbar .menu a {
  margin: 0 10px;
  color: var(--text-color);
  text-decoration: none;
  font-weight: 700;
  transition: 0.5s;
  cursor: pointer;
}

#topbar .menu a:hover {
  color: var(--red);
}

/* ------------------- */

/* Enterance */

#enterance {
  padding: 20px 10%;
  position: relative;
}

#enterance h1 {
  font-size: 28px;
  font-weight: 700;
  line-height: 32.2px;
}

#enterance h3 {
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
}

#enterance p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

#enterance a.button {
  padding: 10px 40px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  background-color: var(--red);
  color: var(--white);
  border: none;
  transition: 0.5s;
  text-decoration: none;
}

#enterance a.button:hover {
  background-color: var(--red-hover);
}

#enterance .rectangle {
  width: 50%;
  height: 80%;
  position: absolute;
  top: 0px;
  left: 25%;
  overflow: hidden;
}

#enterance .rectangle .corner-line {
  position: absolute;
  background-color: var(--red);
}

#enterance .rectangle .corner-line.vertical {
  width: 6px;
  height: 50px;
}

#enterance .rectangle .corner-line.horizontal {
  width: 50px;
  height: 6px;
}

#enterance .corner-line {
  position: absolute;
  width: 4px;
  height: 50px;
  background-color: var(--red); /* Kenar çizgisi rengi */
}

#enterance .rectangle .top-left,
#enterance .rectangle .top-right,
#enterance .rectangle .bottom-left,
#enterance .rectangle .bottom-right {
  background-color: var(--red); /* Köşe çizgisi rengi */
}

#enterance .rectangle .top-left {
  top: -2px;
  left: -2px;
}

#enterance .rectangle .top-right {
  top: -2px;
  right: -2px;
}

#enterance .rectangle .bottom-left {
  bottom: -2px;
  left: -2px;
}

#enterance .rectangle .bottom-right {
  bottom: -2px;
  right: -2px;
}

#enterance .rectangle .tracking-rectangle {
  width: 98%; /* Çizginin kalınlığı */
  height: 4px; /* Çizginin uzunluğu */
  position: absolute;
  animation: moveUpDown 4s linear infinite; /* Hareket animasyonu */
  left: 1%;
  z-index: 2;
}

#enterance .rectangle .tracking-rectangle::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(
    to bottom,
    var(--red),
    transparent
  ); /* Çizgi rengi */
  z-index: -1; /* Çizginin altında olmasını sağlar */
}

@keyframes moveUpDown {
  0% {
    transform: translateY(4px);
  }
  50% {
    transform: translateY(200px); /* Orta konum */
  }
  100% {
    transform: translateY(4px); /* Başlangıç ve bitiş konumu */
  }
}

/* ------------------- */

/* Red Bar */

#red-bar {
  width: 100%;
  padding: 50px 10%;
  background-color: var(--red);
}

#red-bar .col-6 {
  width: calc(50% - 20px);
}

#red-bar .box {
  background-color: var(--white);
  padding: 20px;
}

#red-bar .box img {
  width: 50px;
}

#red-bar .box p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

/* ------------------- */

/* Capability */

#capability {
  padding: 50px 10%;
}

#capability img {
  width: 50px;
}

#capability ul.alice-carousel__dots {
  display: none;
}

#capability .alice-carousel__prev-btn,
#capability .alice-carousel__next-btn {
  width: auto;
}

/* ------------------- */

/* SDK */

#sdk {
  padding: 50px 10%;
  background-color: var(--grey);
}

#sdk img {
  height: 50px;
  filter: grayscale(100%);
}

#sdk h3 {
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
}

#sdk ul {
  padding-left: 1rem;
}

/* ------------------- */

/* Use Case */

#use-case {
  padding: 50px 10%;
}

#use-case .col-4 {
  width: calc(33.3% - 20px);
}

#use-case h3 {
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
  color: var(--text-color) !important;
}

#use-case h5 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: var(--text-color) !important;
}

#use-case p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: var(--text-color) !important;
}

/* ------------------- */

/* Contact Us */

#contact {
  padding: 50px 10%;
}

#contact h3 {
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
  color: var(--text-color) !important;
}

#contact input,
#contact textarea {
  border-radius: 0;
  background-color: var(--grey);
}

#contact button.send {
  padding: 10px 20px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  background-color: var(--red);
  color: var(--white);
  border: none;
  transition: 0.5s;
}

#contact button.send:hover {
  background-color: var(--red-hover);
}

#contact .form-label span {
  color: var(--red);
}

#contact .form-check-label a {
  color: var(--red);
  text-decoration: none;
  transition: 0.5s;
}

#contact .form-check-label a:hover {
  text-decoration: underline;
  color: var(--red-hover);
}

#contact .form-check-input:checked[type="checkbox"] {
  background-color: var(--red);
}

/* ------------------- */

/* Certificate */

#certificate {
  padding: 50px 10%;
  background-color: var(--grey);
}

#certificate h3 {
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
}

#certificate img {
  height: 50px;
}

/* ------------------- */

/* */

#table {
  padding: 50px 10%;
}

/* ------------------- */

/* Footer */

#footer {
  padding: 50px 10%;
  background-color: var(--black);
}

#footer img.footer-logo {
  width: 150px;
}

#footer .addMail input {
  border-radius: 0;
}

#footer .addMail button {
  border-radius: 0;
  background-color: var(--red);
  color: var(--white);
  border: none;
  transition: 0.5s;
}

#footer .addMail button:hover {
  background-color: var(--red-hover);
}

/*-----------------*/
